import { buildBrandedSearchRelatedCardUrlParams } from '@vakantiesnl/services/src/util/urlUtils';
import { ContentfulLink, LinkCard } from '@vakantiesnl/types/src';
import { useRouter } from 'next/router';

export function useLinkCardUrl(linkCard: LinkCard | ContentfulLink, departureDate?: string[] | null): string {
	const router = useRouter();

	const linkCardQueryParams = 'queryParams' in linkCard ? linkCard?.queryParams : undefined;

	const cardQueryParams = buildBrandedSearchRelatedCardUrlParams(
		linkCardQueryParams ?? '',
		router.query,
		departureDate,
	);

	const baseSlug = 'link' in linkCard ? linkCard.link : linkCard.slug;

	return `${baseSlug}${cardQueryParams ? cardQueryParams : ''}`;
}
