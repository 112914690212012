import { FilterState } from '@vakantiesnl/services/src/stores/filtersStore';

import { isSingleGeoSelected } from '../../geo';

export const shouldShowFallbackTemplate = (filters: FilterState): string | false => {
	const hasCampaign = !!filters.campaign;

	const hasRequiredFilterSelected =
		(filters.themes.length === 1 && !hasCampaign && !filters.accoType.length) ||
		(hasCampaign && !filters.accoType.length && !filters.themes.length) ||
		(!filters.themes.length && !filters.accoType.length) ||
		(!filters.themes.length && filters.accoType.length == 1) ||
		(!filters.themes.length && !filters.accoType.length);

	// No geo and/or required filters selected
	if (!isSingleGeoSelected(filters) || (isSingleGeoSelected(filters) && !hasRequiredFilterSelected)) {
		return false;
	}

	// Geo selected and a campaign
	if (hasCampaign && !filters.accoType.length) {
		return '/fallback-template-campaign-geo';
	}

	// Geo selected and a single theme
	if (filters.themes.length === 1 && !filters.accoType.length) {
		return '/fallback-template-theme-geo';
	}

	// Geo selected and a single accoType
	if (filters.accoType.length === 1 && !filters.themes.length) {
		return '/fallback-template-accotype-geo';
	}

	return '/fallback-template';
};
