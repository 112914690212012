import { UrlObject } from 'url';

type Props = {
	fullSlug: string;
	queryParams: {
		airports?: string | string[];
		durations?: string | string[];
		departureDate: string;
		mealplans?: string | string[];
		s_date?: string | string[];
		s_direct_only?: string | string[];
		s_outbound_flight?: string | string[];
		s_inbound_flight?: string | string[];
		s_roomtype?: string | string[];
		s_mealplan?: string | string[];
		min_price?: string;
		max_price?: string;
	};
};

/**
 * This util generates the accommodationUrl when a user reaches step 0 of the IBE which returns to the accopage
 * contains a ${pathname} with ${query} parameters to mimic the selection of the user
 */
export const generateAccommodationUrl = ({ fullSlug, queryParams }: Props): UrlObject => ({
	pathname: fullSlug,
	query: {
		...(queryParams?.departureDate && { departure_date: queryParams.departureDate }),
		...(queryParams?.durations && {
			durations: Array.isArray(queryParams.durations) ? queryParams.durations.join('+') : queryParams.durations,
		}),
		...(queryParams?.airports && {
			airports: Array.isArray(queryParams.airports) ? queryParams.airports.join('+') : queryParams.airports,
		}),
		...(queryParams?.mealplans && {
			mealplans: Array.isArray(queryParams.mealplans) ? queryParams.mealplans.join('+') : queryParams.mealplans,
		}),
		...(queryParams?.s_date && { s_date: queryParams.s_date }),
		...(queryParams?.s_direct_only && { s_direct_only: queryParams.s_direct_only }),
		...(queryParams?.s_outbound_flight && { s_outbound_flight: queryParams.s_outbound_flight }),
		...(queryParams?.s_inbound_flight && { s_inbound_flight: queryParams.s_inbound_flight }),
		...(queryParams?.s_roomtype && { s_roomtype: queryParams.s_roomtype }),
		...(queryParams?.s_mealplan && { s_mealplan: queryParams.s_mealplan }),
		...(queryParams?.min_price && { min_price: queryParams.min_price }),
		...(queryParams?.max_price && { max_price: queryParams.max_price }),
	},
});
