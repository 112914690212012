/** Check if a single geo is selected by checking the slugs */
export const isSingleGeoSelected = ({
	countrySlugs,
	regionSlugs,
	citySlugs,
}: {
	countrySlugs: string[];
	regionSlugs: string[];
	citySlugs: string[];
}): boolean => {
	if (countrySlugs.length === 0 || countrySlugs.length > 1 || regionSlugs.length > 1 || citySlugs.length > 1)
		return false;

	return true;
};
