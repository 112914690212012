import { MicroCopy, Search, Booking } from '@vakantiesnl/types';

type RoomType = Search.OfferItem['rooms'] | Booking.BookingItem['package']['accommodation']['rooms'];

type UniqueRoom = { count: number; description: string | null; code: string };
type UniqueRooms = Record<string, UniqueRoom>;

/** Util to make rooms unique, sometimes the same roomCode is used with a different description
 * e.g. 1person in a 2person room.
 */
export const getUniqueRoomCode = (roomCode: string, description: string | null): string =>
	`${roomCode}${description ? '-' + description?.replace(/\s+/g, '-') : ''}`;

// can be used on different pages, which consume different backend APIs, returning different interfaces
export const getRoomsDescription = (rooms: RoomType | null, microCopies: MicroCopy): string | null => {
	if (!rooms) return null;
	function isSearchOfferItem(rooms: RoomType): rooms is Search.OfferItem['rooms'] {
		return Array.isArray((rooms as Search.OfferItem['rooms'])[0]?.codes);
	}

	let uniqueRooms: UniqueRooms;

	if (isSearchOfferItem(rooms)) {
		uniqueRooms = rooms.reduce<UniqueRooms>((prev, room) => {
			const uniqueRoomId = getUniqueRoomCode(room.codes[0], room.description);
			return {
				...prev,
				[uniqueRoomId]: {
					count: (prev[uniqueRoomId]?.count ?? 0) + 1,
					code: room.codes[0],
					description: room.description,
				},
			};
		}, {});
	} else {
		uniqueRooms = rooms.reduce<UniqueRooms>((prev, room) => {
			const uniqueRoomId = getUniqueRoomCode(room.code, room.description);
			return {
				...prev,
				[uniqueRoomId]: {
					count: (prev[uniqueRoomId]?.count ?? 0) + 1,
					code: room.code,
					description: room.description,
				},
			};
		}, {});
	}

	return (
		(Object.keys(uniqueRooms).length === 1 ? `${rooms.length}x ` : '') +
		Object.keys(uniqueRooms)
			.map((roomCode) => {
				const room = uniqueRooms[roomCode];

				const description = room.description
					? room.description
					: microCopies[`accommodation.roomCodes.roomCode-${room.code}`]
						? microCopies[`accommodation.roomCodes.roomCode-${room.code}`]
						: roomCode;

				return (Object.keys(uniqueRooms).length > 1 ? `${room.count}-` : '') + description;
			})
			.join(', ')
	);
};

export const getRoomDescription = (offer: Search.OfferItem, roomIndex: number, microCopies: MicroCopy): string => {
	const room = offer.rooms[roomIndex];

	if (!room) return '';

	if (room.description) {
		return room.description;
	}

	if (microCopies[`accommodation.roomCodes.roomCode-${room.codes[0]}`]) {
		return microCopies[`accommodation.roomCodes.roomCode-${room.codes[0]}`];
	}

	return room.codes[0];
};

export const getBoardDescription = (offer: Search.OfferItem, microCopies: MicroCopy): string => {
	const room = offer.rooms[0];

	if (room && microCopies[`accoMealplan_label.${room.board.code}`]) {
		return microCopies[`accoMealplan_label.${room.board.code}`];
	}

	return '';
};
