import { Adlink } from '@vakantiesnl/types/src/adlinks';
import { sortBy } from 'lodash';

/**
 * Format adlinks array in a certain order
 * @param {Adlink[]} adlinks - The array with the adlinks
 * @returns {Adlink[]} - The filtered array of adlinks in the correct order
 */
export const formatAdlinksOnPartnerOrder = (adlinks: Adlink[], partnersOrderToShow: number[]): Adlink[] => {
	const filteredAdlinks = adlinks.filter((adlink) => partnersOrderToShow.includes(adlink.supplierId));

	return sortBy(filteredAdlinks, (adlink) => partnersOrderToShow.indexOf(adlink.supplierId));
};
