export const breakpoints = {
	xs: 320,
	sm: 480,
	md: 768,
	lg: 1024,
	xl: 1200,
};

export function getFromBreakpoint(key: keyof typeof breakpoints): string {
	const value = breakpoints[key];

	return `@media screen and (min-width: ${value}px)`;
}

export function getUpToBreakpoint(key: keyof typeof breakpoints): string {
	const value = breakpoints[key];

	// subtract one from value so there is no styles collision between getFromBreakpoint and getUpToBreakpoint
	// when the same breakpoint is passed to both functions
	// e.g. (min-width: 480) but (max-width: 479)
	return `@media screen and (max-width: ${value - 1}px)`;
}
