import { MicroCopy, Elastic, Search } from '@vakantiesnl/types';

import routeData from '@RouteParams';

/** Typecasting for sso and bp */
const accoTypesRouteData = 'accoTypes' in routeData ? (routeData.accoTypes as { [key: string]: string }) : {};

export const getAccommodationBreadCrumbs = (
	accommodation: Search.FullAccommodationItem,
	microCopies: MicroCopy,
): Partial<Elastic.Location>[] | undefined => {
	if (!Object.keys(accoTypesRouteData).length) return;

	const accoTypeRoute = Object.keys(accoTypesRouteData)
		.filter((routeAccommodationType) => routeAccommodationType === accommodation.type)
		.map((routeKey) => accoTypesRouteData[routeKey as keyof typeof accoTypesRouteData])[0];

	const cityLocation = accommodation.location.find(
		(location: Elastic.Location) => location.destinationType === 'CITY',
	);
	if (!accoTypeRoute || !cityLocation) return;

	return [
		...accommodation.location,
		{
			text: `${microCopies[`accoType.${accommodation.type}.plural`]} ${cityLocation.text}`,
			to: `${cityLocation.to}/${accoTypeRoute}`,
		},
		{
			text: accommodation.title,
			to: undefined,
		},
	];
};
