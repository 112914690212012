import { GeoState } from '@vakantiesnl/services/src/hooks/queries';
import { FilterState } from '@vakantiesnl/services/src/stores/filtersStore';
import { MappedDestinationView } from '@vakantiesnl/types/src/geo';

/** A util to fetch all selected geo based of the slugs in the stateFilters*/
export const getGeoEntitiesFromSlugs = (
	{ countrySlugs, regionSlugs, citySlugs }: FilterState,
	geoData: GeoState,
): {
	country: MappedDestinationView[] | [];
	region: MappedDestinationView[] | [];
	city: MappedDestinationView[] | [];
} => {
	const selectedCountryGeo: MappedDestinationView[] = countrySlugs.length
		? geoData.countries.filter((country: MappedDestinationView) => countrySlugs.includes(country.slug))
		: [];

	const selectedRegionGeo: MappedDestinationView[] | [] =
		selectedCountryGeo?.length && regionSlugs?.length
			? selectedCountryGeo
					.map((selectedCountry) =>
						geoData.regions[selectedCountry.slug]?.filter((region: MappedDestinationView) =>
							regionSlugs.includes(region.slug),
						),
					)
					.flat()
					.filter(Boolean)
			: [];

	const selectedCityGeo: MappedDestinationView[] | [] =
		selectedRegionGeo?.length && citySlugs?.length
			? selectedRegionGeo
					?.map((selectedRegion) =>
						geoData.cities[selectedRegion.slug]?.filter((city: MappedDestinationView) =>
							citySlugs.includes(city.slug),
						),
					)
					.flat()
					.filter(Boolean)
			: [];

	return {
		country: selectedCountryGeo,
		region: selectedRegionGeo,
		city: selectedCityGeo,
	};
};
