import IntlMessageFormat from 'intl-messageformat';

export enum Locale {
	nl = 'nl-NL',
	en = 'en-US',
}

export const allowedLocales = {
	[Locale.nl]: Locale.nl,
	[Locale.en]: Locale.en,
};

export const supportedLocales = Object.keys(allowedLocales);
export const defaultLocale = Locale.nl;

const reactRenderers = {
	b: (str: string | JSX.Element | (string | JSX.Element)[]): JSX.Element => <b key={String(str)}>{str}</b>,
	br: (): JSX.Element => <br />,
};

export const message = (message: string, context?: Record<string, unknown>): string => {
	return new IntlMessageFormat(message, supportedLocales).format({
		...reactRenderers,
		...context,
	}) as string;
};
