import { pickBy } from 'lodash';
import { City } from 'schema-dts';

export const getCitySchemaItem = (
	country: string,
	region: string,
	city: string,
	origin: string,
	pathname?: string,
	totalReviewCount?: number,
	rating?: number,
): City => {
	return pickBy({
		'@type': 'Product',
		address: {
			'@type': 'PostalAddress',
			addressCountry: country,
			addressRegion: region,
			addressLocality: city,
		},
		aggregateRating: rating
			? {
					'@type': 'AggregateRating',
					bestRating: 10,
					worstRating: 1,
					ratingValue: rating,
					ratingCount: totalReviewCount ? totalReviewCount : null,
					reviewCount: totalReviewCount ? totalReviewCount : null,
				}
			: null,
		name: city,
		url: `${origin}${pathname ?? ''}`,
	}) as unknown as City;
};
